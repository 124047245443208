<template>
  <div class="about">
    <h1>Create Model</h1>
    <div class="form">
      <div class="input">
      <label for="modelname">Model Name</label>
      <input name="modelname" type="text" placeholder="Name" @keyup="onNameChange">
      </div>
      <div class="input">
      <label for="modelfile">File</label>
      <input name="modelfile" type="file"  @change="onFileChange">
      </div>
      <button @click="onUploadFile" v-show="progress===null|| progress==='100%'">Submit</button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default{
  name:'UploadView',
  data(){
    return{
      selectedName : "",
      selectedFile: "",
      progress:null
    }
  },
  methods:{
    onNameChange: function(e){
      this.selectedName = e.target.value;
    },
    onFileChange: function(e){
      const selectedFile = e.target.files[0];
      this.selectedFile =  selectedFile;
      console.log(this.selectedFile);
    },
    onUploadFile: function(){
      const formData = new FormData();
      formData.append("file", this.selectedFile);

      axios
      .post(`http://141.136.35.32:3000/addModel?modelName=${this.selectedName}&modelPath=${this.selectedFile.name}`,formData,{
        onUploadProgress: ProgressEvent => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
              +"%";
            this.progress = progress;
            console.log(progress);
          }
      })
      .then(res=>{
        console.log(res);
      })
      .catch(err=>{
        console(err);
      })
    }
  }
}
</script>

<style scoped>
.input{
  width: 320px;
}
label{
  display: block;
  margin-bottom: 6px;
}

.form{
  display: flex;
  flex-direction: column;
  gap:12px;
  align-items: center;
}
</style>
