<template>
  <div class="home">
    <h1>Model List</h1>
    <div class="model_list">
    <div class="model_list_titles">
    <div>Model name</div>
    <div>Model path</div>
    </div>
    <div class="model_list_elements">
    <div v-for="model in models" v-bind:key="model" class="model_list_element">
    <div><a :href="`http://viewer.nowarlab.com/?model=${model.path}`">{{model.name}}</a></div>
    <div>{{model.path}}</div>
    </div>
    </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {

  },
  data(){
      return{
        models:null
      }
  },
  mounted(){
    fetch('http://internal.nowarlab.com:3000/models')
    .then(response=>response.json())
    .then(data=>(this.models = data.models))
  }
}
</script>

<style scoped>
.model_list{
  width:60%;
  margin: 0 auto;
}
.model_list_titles{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #ccc;
  padding: 10px;
}
.model_list_elements{
  display: flex;
  flex-direction: column;
  gap:10px
}
.model_list_element{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}
</style>


